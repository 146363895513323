<template>
  <section class="app-container">
    <Filter
      @search="handleSearch"
      @export-click="exportClick"
      @refresh-table="handleResetTable"
      @refresh-table-height="handleResetTableHeight"
    />
    <SkillList ref="skillListRef"></SkillList>
  </section>
</template>
<script>
import { defineComponent, ref } from "vue";
import Filter from "./components/Filter.vue";
import SkillList from "./components/SkillList";
import { downloadFile } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common";

export default defineComponent({
  name: "exportSkill",
  components: {
    SkillList,
    Filter,
  },
  setup() {
    const { $api } = useGlobalPropertyHook();

    const skillListRef = ref();
    const handleSearch = (searchParams) => {
      skillListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      skillListRef.value.doSearch();
    };
    const handleResetTableHeight = () => {
      skillListRef.value.getSize();
    };

    const exportClick = (searchParams) => {
      $api.skillExport(searchParams).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      skillListRef,
      handleSearch,
      handleResetTable,
      exportClick,
      handleResetTableHeight,
    };
  },
});
</script>
