<template>
  <div ref="customEntryRef">
    <a-modal :visible="visible" :getContainer="() => $refs.customEntryRef" title="筛选项排序" width="28vw" centered
      @cancel="closeModal">
      <template #footer>
        <div class="btn-left">
          <Tooltip :title="'重置恢复默认值'">
            <a-button type="primary" ghost @click="reset">重置</a-button>
          </Tooltip>
          <span class="font-blue">仅前{{ sortFlag ? 5 : 4 }}项筛选项展示于页面</span>
        </div>
        <a-button class="gray" @click="closeModal">取消</a-button>
        <a-button type="primary" @click="saveModal">确定</a-button>
      </template>
      <div class="box-main">
        <div class="box-item">
          <div class="tree-box box-margin">
            <a-table :class="sortFlag ? 'row-blue' : ''" :showHeader="false" :customRow="customRow" rowKey="inputDataType"
              :dataSource="dataList" :columns="columns" :pagination="false">
              <template #index="{ index }">
                <a-space class="flex-box">
                  <span>{{ index + 1 }}</span>
                </a-space>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted, watch,
} from "vue";
import Tooltip from '@/components/Tooltip'
import { useGlobalPropertyHook } from '@/hooks/common'
const dataList = ref([])
const columns = ref([
  {
    title: "序号",
    dataIndex: "index",
    key: 'index',
    ellipsis: true,
    slots: { customRender: "index" },
    align: 'center'
  },
  {
    title: "模块名",
    dataIndex: "code",
    key: 'code',
    ellipsis: true,
    /*customRender: ({record}) => {
      if(record.secondMenu){
        return record.secondMenu
      }else{
        return record.firstMenu
      }
    }*/
  },
])
export default defineComponent({
  name: "CustomEntry",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    checkList: {
      type: Array,
      default: () => {
        return []
      }
    },
    sortFlag: {
      type: Boolean,
      default: false
    }
  },
  emits: ['closeModal', 'reset'],
  components: { Tooltip },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    /*const state = reactive({
      userData: {}
    })*/
    const init = () => {
      let list = []
      list = props.checkList.map((item) => {
        return item
      })
      list.forEach((item) => {
        if (item.code == 'city') {
          item.code = 'City'
        }
        if (item.code == 'building') {
          item.code = 'Building'
        }
        if (item.code == 'floor') {
          item.code = 'Floor'
        }
      })
      dataList.value = list
    }
    const reset = () => {
      let params = {
        isQueryDefault: 1,
        isQuery: false
      }
      ctx.emit('reset', params);
    }
    const closeModal = () => {
      let params = {
        isQueryDefault: 0,
        isQuery: false
      }
      ctx.emit('reset', params);
      ctx.emit('closeModal');
    }
    const params = ref([])
    const saveModal = () => {
      params.value = []
      dataList.value.forEach((item, index) => {
        params.value.push({
          ...item,
          sort: index + 1 + ''
        })
      })
      $api.saveFilterSort(params.value).then((res) => {
        console.log(res, '初始化筛选项顺序')
        let params = {
          isQueryDefault: 0,
          isQuery: true
        }
        ctx.emit('reset', params);
        closeModal()
      })
    }
    const position = {
      start: undefined,
      end: undefined,
      sourceEl: undefined,
    }
    // 排序
    const reorder = ({ start, end }) => {
      if (start !== undefined && end !== undefined) {
        if (start > end) {
          // 当开始大于结束
          let temp = dataList.value[start]
          dataList.value.splice(start, 1);
          dataList.value.splice(end, 0, temp)
        } else if (start < end) {
          // 结束大于开始
          let temp = dataList.value[start]
          dataList.value.splice(start, 1)
          dataList.value.splice(end, 0, temp)
        }
      }
    }
    function rowKey(record) {
      return record.id
    }
    function customRow(_record, index) {
      return {
        style: {
          cursor: "move",
        },
        // 鼠标移入
        onMouseenter: (event) => {
          // 兼容IE
          let ev = event || window.event;
          ev.target.draggable = true;
        },
        // 开始拖拽
        onDragstart: (event) => {
          // 兼容IE
          let ev = event || window.event;
          // 阻止冒泡
          ev.stopPropagation();
          // 得到源目标数据;
          position.start = index
          const tr = ev.target
          position.sourceEl = tr
        },
        // 拖动元素经过的元素
        onDragover: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
        },
        // 松开
        onDrop: (event) => {
          let ev = event || window.event;
          // 阻止默认行为
          ev.preventDefault();
          position.end = index
          reorder(position);
          animation(position)
        },
      };
    }
    // 实现动画效果
    function animation({ start, end, sourceEl }) {
      // 48 是每行的高度，也可以自动获取，根据情况而定
      let count = 48 * (start - end)
      sourceEl.style.translate = `0px ${count}px`
      setTimeout(() => {
        sourceEl.style.transition = "all 0.5s"
        sourceEl.style.translate = `0px 0px`
      })
    }
    watch(
      () => [props.visible, props.checkList],
      (val) => {
        if (val[0]) {
          init()
        }
      },
      { immediate: true }
    );
    onMounted(() => {
    })
    return {
      columns,
      dataList,
      reset,
      closeModal,
      saveModal,
      rowKey,
      customRow
    };
  },
});
</script>

<style scoped lang="less">
:deep(.ant-modal-body) {
  padding: 0;
}

.box-main {
  display: flex;
  font-size: 16px;
  color: #4d4d4d;

  .box-item {
    .box-margin {
      margin-top: 8px;
    }

    .tree-title {
      padding-left: 30px;
      height: 48px;
      line-height: 48px;
      color: rgba(77, 77, 77, 0.6);
      background: #fafafa;
    }

    .tree-box {
      max-height: 324px;
      overflow: auto;
    }
  }
}

.btn-left {
  float: left;

  span {
    margin-left: 8px;
  }
}

.gray {
  color: #999 !important;
  border-color: #999 !important;
}

:deep(.ant-table-tbody > tr > td) {
  border: 0;
  padding: 0 12px;
  line-height: 36px;
}

:deep(.ant-table .ant-table-tbody > tr > td:first-child) {
  padding: 0;
  width: 40px;
  border-right: 1px solid #ededed !important;
}

:deep(.ant-table .ant-table-tbody > tr:nth-child(1) > td:first-child) {
  color: #3182CE;
  background: #E9F6FE;
}

:deep(.ant-table .ant-table-tbody > tr:nth-child(2) > td:first-child) {
  color: #3182CE;
  background: #E9F6FE;
}

:deep(.ant-table .ant-table-tbody > tr:nth-child(3) > td:first-child) {
  color: #3182CE;
  background: #E9F6FE;
}

:deep(.ant-table .ant-table-tbody > tr:nth-child(4) > td:first-child) {
  color: #3182CE;
  background: #E9F6FE;
}

.row-blue {
  :deep(.ant-table .ant-table-tbody > tr:nth-child(5) > td:first-child) {
    color: #3182CE;
    background: #E9F6FE;
  }
}

:deep(.ant-table .ant-table-tbody > tr > td:nth-child(2)) {
  padding-left: 30px;
}

:deep(.ant-table-row) {
  background: url("../assets/iconfont/drap.png") 45px no-repeat;
  background-size: 20px 20px;
}

:deep(.ant-tree) {
  font-size: 16px;
}

.font-blue {
  color: #3182CE;
}

.icon-btn {
  cursor: pointer;
}</style>
