import {dictionaryOpt} from '@/utils/common'

/**
 * 处理org2、org5.1、team、offering、org4.2的联动关系
 * @param orgAllList
 * @param formOrgData
 * @param orgOpsObj
 * @param type
 * @returns {{orgsValue: *, orgOpsList: *}}
 */
export function getValueAndOps(orgAllList=[],formOrgData,orgOpsObj,type) {
    const orgTypes = ['orgTwo','orgFiveOne','orgThreeTwo','orgFourOneOne','orgFourTwo']
    // 调整4个下拉框的value值：下级清空
    const data = initUnderData(orgTypes,formOrgData,type)

    // 查询
    const orgsData = getOrgOptData(
        orgAllList,
        formOrgData.orgTwoList,
        formOrgData.orgFiveOneList,
        formOrgData.orgThreeTwoList,
        formOrgData.orgFourOneOneList,
        formOrgData.orgFourTwoList,
    )

    // team筛选项内容不受offering 影响
    const teamOpsData = getOrgOptData(
        orgAllList,
        formOrgData.orgTwoList,
        formOrgData.orgFiveOneList,
        [],
        [])

    // 更新4个下拉框对应option数据
    orgTypes.forEach((item) => {
        if(type !== item || formOrgData[item+'List']?.length === 0) {
            if(formOrgData.orgFourTwoList){
                orgOpsObj[item+'Ops'] = dictionaryOpt(orgsData[item+'Ops'])
            } else {
                orgOpsObj[item+'Ops'] = item === 'orgThreeTwo' ? dictionaryOpt(teamOpsData[item+'Ops']) : dictionaryOpt(orgsData[item+'Ops'])
            }
        }
    })
    return {
        orgsValue:data,
        orgOpsList:orgOpsObj
    }
}
//
/**
 * 调整正下级下拉框的value：变更选项，下级下拉框value清空
 * @param orgTypes
 * @param formOrgData
 * @param type
 * @returns {*}
 */
export function initUnderData(orgTypes,formOrgData,type) {
    for(let i = orgTypes.indexOf(type); i< orgTypes.length; i++ ) {
        if(i + 1 !== orgTypes.length) {
            if(formOrgData[orgTypes[i+1]+'List']?.length> 0) {
                // formData[orgTypes[i+1]+'List'] = formData[orgTypes[i+1]+'List'].filter((item)=> orgOpsObj[orgTypes[i+1]+'Ops'].includes(item))
                // 清空下级数据
                formOrgData[orgTypes[i+1]+'List'] = []
            }
        }
    }
    return formOrgData
}
/**
 * 查询org2,org5.1,team,offering下拉内容
 * @param orgAllList
 * @param orgTwo
 * @param orgFiveOne
 * @param orgThreeTwo
 * @param orgFourOneOne
 * @returns {{}}
 */
export function getOrgOptData (orgAllList=[], orgTwo=[], orgFiveOne=[], orgThreeTwo=[], orgFourOneOne=[],orgFourTwo=[]) {
    const orgTypes = ['orgTwo','orgFiveOne','orgThreeTwo','orgFourOneOne','orgFourTwo']
    const selectedOrgObj = {
        orgTwo: {},
        orgFiveOne: {},
        orgThreeTwo: {},
        orgFourOneOne: {},
        orgFourTwo: {},
    }
    orgTwo.length> 0 && orgTwo.forEach(item=> {
        selectedOrgObj.orgTwo[item] = true
    })
    orgFiveOne.length> 0 && orgFiveOne.forEach(item=> {
        selectedOrgObj.orgFiveOne[item] = true
    })
    orgThreeTwo.length> 0 && orgThreeTwo.forEach(item=> {
        selectedOrgObj.orgThreeTwo[item] = true
    })
    orgFourOneOne.length> 0 && orgFourOneOne.forEach(item=> {
        selectedOrgObj.orgFourOneOne[item] = true
    })
    orgFourTwo.length> 0 && orgFourTwo.forEach(item=> {
        selectedOrgObj.orgFourTwo[item] = true
    })
    const orgs = orgAllList.filter((item) => {
        if((orgTwo.length> 0 ? selectedOrgObj.orgTwo[item.orgTwo]: true) &&
            (orgFiveOne.length> 0 ? selectedOrgObj.orgFiveOne[item.orgFiveOne]: true) &&
            (orgThreeTwo.length> 0 ? selectedOrgObj.orgThreeTwo[item.orgThreeTwo]: true)&&
            (orgFourOneOne.length> 0 ? selectedOrgObj.orgFourOneOne[item.orgFourOneOne]: true) &&
            (orgFourTwo.length> 0 ? selectedOrgObj.orgFourTwo[item.orgFourTwo]: true)
        ) {
            return item
        }
    })
    const orgData = {}
    orgTypes.forEach((item) => {
        orgData[item + 'Ops'] = getChangedOrgOpt(orgs,item).sort()
    })
    return orgData
}

/**
 * 获取对应的下拉option数组
 * @param orgs
 * @param type
 * @returns {[]}
 */
export function getChangedOrgOpt(orgs,type) {
    const orgOptList = []
    removeDuplicate(orgs,type).forEach((item) => {
        orgOptList.push(item[type])
    })
    return orgOptList
}

/**
 * 去重
 * @param arr 需要去重的数组
 * @param key 根据key字段去重
 * @returns {*}
 */
export function removeDuplicate(arr,key) {
    const res = new Map();
    return arr.filter(arr => !res.has(arr[key]) && res.set(arr[key], arr[key]));
}
