<template>
  <section class="org-list">
    <a-table
      ref="skillListRef"
      :columns="columns"
      :data-source="skillList"
      rowKey="id"
      :scroll="{
        y: tableHeight,
        x: 1200,
        scrollToFirstRowOnChange: true,
      }"
      :pagination="pagination"
      @change="handleTableChange"
    >
    </a-table>
  </section>
</template>
<script>
import {
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  ref,
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { useTableHeight } from "@/utils/common";

export default defineComponent({
  name: "CostCtrList",
  components: {},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const skillListRef = ref();
    const columns = [
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Consultant",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Org5.1",
        dataIndex: "orgFiveOne",
        key: "orgFiveOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Team",
        dataIndex: "orgThreeTwo",
        key: "orgThreeTwo",
        ellipsis: true,
        width: 160,
      },
      {
        title: "Offering",
        dataIndex: "orgFourOneOne",
        key: "orgFourOneOne",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skill ID",
        dataIndex: "skillId",
        key: "skillId",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skill Star",
        dataIndex: "skillStar",
        key: "skillStar",
        ellipsis: true,
        width: 150,
        sorter: true,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Remarks",
        dataIndex: "remarks",
        key: "remarks",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Create Time",
        dataIndex: "createTime",
        key: "createTime",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Create Operator",
        dataIndex: "createOperator",
        key: "createOperator",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operation Time",
        dataIndex: "operationTime",
        key: "operationTime",
        ellipsis: true,
        width: 200,
      },
      {
        title: "Operator",
        dataIndex: "operator",
        key: "operator",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Dict Code",
        dataIndex: "dictCode",
        key: "dictCode",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skills",
        dataIndex: "dictValue",
        key: "dictValue",
        ellipsis: true,
        width: 150,
      },
      {
        title: "Skill Group",
        dataIndex: "dictRemark",
        key: "dictRemark",
        ellipsis: true,
        width: 150,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const skillList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
        sort,
      };
      $api.skillExportList(params).then((res) => {
        pagination.total = res.data.total;
        skillList.value = res.data.resource;
      });
    };

    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };
    const sort = reactive({
      order: "",
      field: "",
    });
    const handleTableChange = (pag, filters, sorter) => {
      if (!sorter.order) {
        sorter.order = "";
        sorter.field = "";
      }
      Object.assign(sort, sorter);
      doSearch();
    };

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      skillListRef,
      columns,
      pagination,
      skillList,
      searchClick,
      tableHeight,
      doSearch,
      handleTableChange,
      getSize,
    };
  },
});
</script>
<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 28px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
