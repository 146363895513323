<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout
        @doReset="resetForm"
        @doSearch="doSearch"
        ref="filterLayoutRef"
        @doCollapseEffect="getChartContainerStyle"
        @showMoreForm="showMoreForm"
        :moreBtnVisible="moreBtnVisible"
        :isShowMoreFlag="isShowMoreList"
      >
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <template v-for="(item,index) in queryList" :key="index">
              <template v-if="index<=4">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type==='2'">
                    <a-select
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        allowClear
                        show-search
                    >
                    </a-select>
                  </template>
                  <template v-if="item.type==='3'">
                    <a-input
                        v-model:value="formState[item.inputDataType]"
                        placeholder="Name/GGID"
                        enter-button
                        allowClear
                        autocomplete="off"
                    />
                  </template>
                  <template v-if="item.type==='5'">
                    <SelectWithAll
                        v-model:value="formState[item.inputDataType]"
                        :options="getOptions(item.inputDataType)"
                        placeholder="请选择"
                        mode="multiple"
                        showArrow
                        allowClear
                        @change="getSelectChange(item.inputDataType)"
                        :tag-count="item.inputDataType==='gradeList'?3:1"
                    >
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
            <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm" @moreFormSearch="moreSeachHanlder">
              <template v-for="(item,index) in queryList" :key="index">
                <template v-if="index>4">
                  <a-form-item :label="item.code" name="name" class="rms-form-item">
                    <template v-if="item.type==='2'">
                      <a-select
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          allowClear
                          show-search
                      >
                      </a-select>
                    </template>
                    <template v-if="item.type==='3'">
                      <a-input
                          v-model:value="formState[item.inputDataType]"
                          placeholder="Name/GGID"
                          enter-button
                          allowClear
                          autocomplete="off"
                      />
                    </template>
                    <template v-if="item.type==='5'">
                      <SelectWithAll
                          v-model:value="formState[item.inputDataType]"
                          :options="getOptions(item.inputDataType)"
                          placeholder="请选择"
                          mode="multiple"
                          showArrow
                          allowClear
                          @change="getSelectChange(item.inputDataType)"
                          :tag-count="item.inputDataType==='gradeList'?3:1"
                      >
                      </SelectWithAll>
                    </template>
                  </a-form-item>
                </template>
              </template>
            </MoreFormList>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap">
            <Tooltip title="导出"></Tooltip>
            <a-button
                class="left"
                type="primary"
                ghost
                @click="exportClick"
                v-auth="['system:skill:export']"
            >
              <ExportOutlined />
            </a-button>
          </div>
        </template>
      </FilterLayout>
    </div>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="true" :checkList="checkList" @closeModal="closeModal" @reset="queryFormList"></FormModalBox>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {cloneDeep, debounce} from "lodash";
import { getValueAndOps } from "@/utils/orgSelectData";
import { ExportOutlined } from "@ant-design/icons-vue";
import MoreFormList from '@/components/MoreFormList'
import FormModalBox from '@/components/FormModalBox'
import {dictionaryOpt, dictionaryOptionsCode} from '@/utils/common'
import Tooltip from '@/components/Tooltip'
export default defineComponent({
  name: "TopFilter",
  components: {Tooltip, ExportOutlined,MoreFormList,FormModalBox },
  emits: ["search", "export-click", "refresh-table", "refresh-table-height"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      Object.assign(formState,initData)
      updateParams();
      ctx.emit("search", currentState);
      getSkillsAndGroup();
      getOrgList();
    };
    const orgFiveOneOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const gradeOps = ref([])
    const skillsOps = ref([]);
    const skillGroupOps = ref([]);
    const initData = {
      status: undefined,
      condition: "",
      orgFiveOneList: [],
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      gradeList: [],
      skills: [],
      skillGroup: [],
    }
    const formState = reactive({...initData});
    const orgOpsObj = reactive({
      orgFiveOneOps: [],
      orgThreeTwoOps: [],
      orgFourOneOneOps: [],
    });
    const getOrgList = () => {
      $api.getOrgTwo_Three_Four_FiveList().then((res) => {
        const { orgFourOneOneList, orgFiveOneList, orgThreeTwoList } = res.data;
        orgOpsObj.orgFiveOneOps = dictionaryOpt(orgFiveOneList);
        orgOpsObj.orgThreeTwoOps = dictionaryOpt(orgThreeTwoList);
        orgOpsObj.orgFourOneOneOps = dictionaryOpt(orgFourOneOneList);
      });
    };
    const orgAllList = ref([]);
    const getOrgRelatedList = () => {
      $api.getAllOrgRelatedList().then((res) => {
        orgAllList.value = res.data;
      });
    };
    const orgThreeTwoChange = (type) => {
      const formOrgData = {
        orgFiveOneList: formState.orgFiveOneList,
        orgThreeTwoList: formState.orgThreeTwoList,
        orgFourOneOneList: formState.orgFourOneOneList,
      };
      const { orgsValue, orgOpsList } = getValueAndOps(
        orgAllList.value,
        formOrgData,
        orgOpsObj,
        type
      );
      console.log(orgsValue, orgOpsList, 'orgsValue, orgOpsList')
      Object.assign(formState, orgsValue);
      Object.assign(orgOpsObj, orgOpsList);
    };
    const getSkillsAndGroup = () => {
      const params = {
        condition: "",
        skills: [],
        skillGroup: [],
      };
      $api.getSkillsAndSkillGroup(params).then((res) => {
        skillsOps.value = dictionaryOpt(res.data.skills);
        skillGroupOps.value = dictionaryOpt(res.data.skillGroup);
      });
    };

    const getGradeOps = () => {
      $api.getDictionaryList({params: {dictType: "grade",},}).then((res) => {
        gradeOps.value = dictionaryOptionsCode(res.data.grade);
        gradeOps.value.sort((a,b) => {return a.dictSort - b.dictSort})
        gradeOps.value = gradeOps.value.filter(item => item.dictValue !== 'J1' && item.dictValue !== 'J2' && item.dictValue !== 'J3')
      });
    };
    const skillsChange = () => {
      const params = {
        condition: "",
        skills: formState.skills,
      };
      $api.getSkillsAndSkillGroup(params).then((res) => {
        skillGroupOps.value = dictionaryOpt(res.data.skillGroup);
      });
    };
    const skillGroupChange = () => {
      const params = {
        condition: "",
        skillGroup: formState.skillGroup,
      };
      $api.getSkillsAndSkillGroup(params).then((res) => {
        skillsOps.value = dictionaryOpt(res.data.skills);
      });
    };
    let currentState = {};
    const updateParams = () => {
      currentState = cloneDeep(formState);
    };
    const doSearch = () => {
      updateParams();
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    const isShowHeight = ref(false)
    const getChartContainerStyle = () => {
      isShowHeight.value = !isShowHeight.value
      ctx.emit("refresh-table-height");
    };
    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }
    const queryList = ref([])
    const checkList = ref([])
    const moreBtnVisible = ref(false)
    const queryFormList = (data) => {
      $api.queryFilterSort({
        firstMenu: 'basicDataManagement',
        secondMenu: 'skillInfo',
        isQueryDefault: data.isQueryDefault
      }).then((res) => {
        if(data.isQuery){
          queryList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
          queryList.value.length > 5?moreBtnVisible.value = true :moreBtnVisible.value = false
          checkList.value=[...queryList.value]
        }else{
          checkList.value = res.data.filter((item)=>{
            return item.isDeleted==='0'
          })
        }
      });
    };
    const getOptions = (data)=> {
      switch (data) {
        case 'orgThreeTwoList':
          return orgOpsObj.orgThreeTwoOps
        case 'orgFourOneOneList':
          return orgOpsObj.orgFourOneOneOps
        case 'orgFiveOneList':
          return orgOpsObj.orgFiveOneOps
        case 'gradeList':
          return gradeOps.value
        case 'skillGroup':
          return skillGroupOps.value
        case 'skills':
          return skillsOps.value
        default :
          ''  // 如果没
      }
    }
    const getSelectChange = debounce((data) => {
      switch (data) {
        case 'orgThreeTwoList':
          return orgThreeTwoChange('orgThreeTwo')
        case 'orgFourOneOneList':
          return orgThreeTwoChange('orgFourOneOne')
        case 'orgFiveOneList':
          return orgThreeTwoChange('orgFiveOne')
        case 'skillGroup':
          return skillGroupChange()
        case 'skills':
          return skillsChange()
        default :
          ''  // 如果没
      }
    },500)
    const isShowMoreList = ref(false)
    const isShowFormSortBox = ref(false)
    const showMoreForm = (data) => {
      isShowMoreList.value = data
    }
    const openFormSortBox = () => {
      isShowFormSortBox.value = true
    }
    const closeMoreForm = (data) => {
      if(data){
        console.log(123)
      }
      isShowMoreList.value = false
    }
    const closeModal = ()=> {
      isShowFormSortBox.value = false
    }
    onMounted(() => {
      // nextTick(doSearch);
      getGradeOps()
      getOrgList();
      getOrgRelatedList();
      getSkillsAndGroup();
      getChartContainerStyle()
      setTimeout(queryFormList({isQueryDefault:0,isQuery: true}),500)
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });

    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      exportClick,
      orgOpsObj,
      orgFiveOneOps,
      orgThreeTwoOps,
      orgFourOneOneOps,
      skillsOps,
      skillGroupOps,
      orgThreeTwoChange,
      updateParams,
      getOrgRelatedList,
      getGradeOps,
      skillsChange,
      skillGroupChange,
      getSkillsAndGroup,
      getChartContainerStyle,
      isShowHeight,
      queryFormList,
      queryList,
      checkList,
      getOptions,
      getSelectChange,
      isShowMoreList,
      showMoreForm,
      moreBtnVisible,
      openFormSortBox,
      isShowFormSortBox,
      closeMoreForm,
      closeModal,
      moreSeachHanlder
    };
  },
});
</script>
<style scoped lang="less">
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
