<template>
  <div v-if="visible" class="more-form-list" v-bind="$attrs">
    <div class="top-close">
      <CloseOutlined class="close" @click="close(false)" />
    </div>
    <div class="flex-box">
      <slot></slot>
    </div>
    <div class="footer">
      <div class="fotterLeft">
        <a-button type="primary" @click="open">
          自定义排序
        </a-button>
      </div>
      <div class="fotterRight">
        <a-button class="gray" type="primary" ghost @click="close(true)">
          关闭
        </a-button>
        <a-button class="search" type="primary" @click="moreFormSearch">
           搜索
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>

import { defineComponent } from "vue";
import { CloseOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  name: "MoreFormList",
  components: {
    CloseOutlined
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },
  emits: ["close", "open","moreFormSearch"],
  setup(props, { emit }) {
    const close = (data) => {
      emit("close", data)
    }
    const open = () => {
      emit("open")
    }
    const moreFormSearch = () => {
      emit('moreFormSearch')
    }

    return {
      close,
      open,
      moreFormSearch
    }
  }
});
</script>

<style lang="less" scoped>
.more-form-list {
  position: absolute;
  top: 100% + 8px;
  right: -60px;
  width: 580px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;

  .top-close {
    padding: 12px;
    height: 44px;
    border-bottom: 1px solid #ebe9e9;

    .close {
      position: absolute;
      right: 12px;
      display: block;
      width: 20px;
      height: 20px;
      cursor: pointer;

      :deep(svg) {
        width: 100%;
        height: 100%;
      }
    }
  }

  .flex-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0 0 10px;
    max-height: 400px;
    overflow: auto;

    :deep(.ant-form-item) {
      width: 50%;
      padding: 0 12px;
    }
  }

  .footer {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #ebe9e9;

    .gray {
      color: #999;
      border: 1px solid #999;
    }

    .fotterRight {
      display: flex;
      align-items: center;
      .search {
        margin-left: 10px;
      }
    }
  }
}

:deep(.rms-form) {
  flex: 1;
}
</style>
